import React from 'react';
import { LoadingSpinner } from './components/LoadingSpinner';
import { useAuthContext } from './context/AuthContext';
import './index.css';
import { Router } from './router';

const App: React.FC = () => {
  const { isLoading } = useAuthContext();

  return isLoading ? <LoadingSpinner /> : <Router />;
};

export { App };
