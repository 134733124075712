import React from 'react';
import { useLocalStorageState } from '@umijs/hooks';
import { AuthContextType, AuthUser } from '../../types/auth';
import { getAuthStatus } from '../../api/refactor/auth';

const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);
const useAuthContext = () => React.useContext(AuthContext);

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [clinicTime, setClinicTime] = useLocalStorageState<[string, string]>(
    'timeRange',
    ['00:00:00', '23:00:00']
  );
  const [user, setUser] = useLocalStorageState<AuthUser>('user');
  const [clinicName, setClinicName] = useLocalStorageState<string>(
    'clinicName',
    ''
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (user) {
      getAuthStatus()
        .catch(() => {
          setUser(undefined);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    document.title = clinicName;
  }, [clinicName]);

  const auth = React.useMemo(
    () => ({
      user,
      setUser,
      clinicTime,
      setClinicTime,
      clinicName,
      setClinicName,
      isLoading
    }), [
      user,
      clinicTime,
      clinicName,
      isLoading
    ]
  );

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export { useAuthContext, AuthContextProvider };
