import React from 'react';
import { useList } from '../../hooks/useList';
import {
  CallbackCode,
  CallbackCodesContextType
} from '../../types/callbackCodes';

const CallbackCodesContext = React.createContext<CallbackCodesContextType>(
  {} as CallbackCodesContextType
);

const useCallbackCodesContext = () => React.useContext(CallbackCodesContext);

const CallbackCodesContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [list, setList, { get, add, remove, edit }] = useList<CallbackCode>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(0);

  const callbackCodesList = React.useMemo<CallbackCodesContextType>(
    () => ({
      callbackCodes: list,
      getCallbackCode: get,
      addCallbackCode: add,
      removeCallbackCode: remove,
      editCallbackCode: edit,
      setCallbackCodes: setList,
      callbackCodesLoading: loading,
      setCallbackCodesLoading: setLoading,
      callbackCodesCount: count,
      setCallbackCodesCount: setCount
    }),
    [
      list,
      setList,
      get,
      add,
      remove,
      edit,
      loading,
      setLoading,
      count,
      setCount
    ]
  );

  return (
    <CallbackCodesContext.Provider value={callbackCodesList}>
      {children}
    </CallbackCodesContext.Provider>
  );
};

export { CallbackCodesContextProvider, useCallbackCodesContext };
