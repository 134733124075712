export const ROUTES = {
  OUTCAST: '/outcast',
  LOGIN: '/login',
  NOT_FOUND: '/404',
  USERS: '/users',
  GROUPS: '/groups',
  CLIENTS: '/clients',
  SCHEDULES: '/schedules',
  CALLS: '/calls',
  TICKETS: '/tickets',
  PRODUCTS: '/products',
  TOOTH_ZONES: '/tooth_zones',
  SOLUTIONS: '/solutions',
  ISSUES: '/issues',
  EVENTS: '/events',
  ME: '/me',
  PLANS: '/plans',
  REVISIONS: '/revisions',
  DEPARTMENTS: '/departments',
  CALLBACK_CODES: '/callback_codes',
  SNAPSHOTS: '/xrays',
  MESSAGES: '/messages',
  VARIABLES: '/vars',
  DOCUMENTS_TEMPLATES: '/documents/templates'
} as const;
