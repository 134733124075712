import React from 'react';
import { Schedule, Schedules } from '../types/schedules';

const useSchedules = () => {
  const [schedules, setSchedules] = React.useState<Schedules>({});

  const addSchedule = (data: Schedule, userId: number) => {
    setSchedules((prev: Schedules) => ({
      ...prev,
      [userId]: [...prev[userId], data]
    }));
  };

  const removeSchedule = (id: number, userId: number) => {
    setSchedules((prev: Schedules) => ({
      ...prev,
      [userId]: prev[userId].filter((schedule) => schedule.id !== id)
    }));
  };

  const editSchedule = (id: number, userId: number, data: any) => {
    setSchedules((prev: Schedules) => ({
      ...prev,
      [userId]: prev[userId].map((schedule) =>
        schedule.id === id ? { ...schedule, ...data } : schedule
      )
    }));
  };

  const getSchedule = (id: number, userId: number) =>
    schedules[userId].find((schedule) => schedule.id === id);
  return {
    addSchedule,
    removeSchedule,
    editSchedule,
    getSchedule,
    schedules,
    setSchedules
  };
};

export { useSchedules };
