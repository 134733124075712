import { API } from '../../constants';
import { AuthData } from '../../types/auth';
import { Response } from '../../types/server';
import { api } from './base/api';

type GetAuthStatusResponse = Response<[], null>;

type LoginResponse = Response<AuthData, null>;

type LogoutResponse = Response<[], null>;

type LoginData = {
  login: string;
  password: string;
};

export const getAuthStatus = () => {
  return api.get<GetAuthStatusResponse>(API.STATUS);
};

export const login = (data: LoginData) => {
  return api.post<LoginResponse>(API.LOGIN, data);
};

export const logout = () => {
  return api.post<LogoutResponse>(API.LOGOUT, undefined);
};
