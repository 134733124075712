import './MobileLayout.css';

import { Toast } from 'antd-mobile';
import React, { useEffect, useState } from 'react';
import { Dropdown, Layout, Menu } from 'antd';
import { UserInfoButton } from '../components/UserInfoButton';
import { LogoutButton } from '../components';
import { useAuthContext } from '../../../context/AuthContext';
import { useUsersContext } from '../../../context/UsersContext';
import { useGroupsContext } from '../../../context/GroupsContext';
import { getGroups } from '../../../api/groups';
import { getUser } from '../../../api/users';
import { HamburgerIcon } from '../../HamburgerIcon';
import {
  convertExtendedRoutesToMobileMenuItems,
  filterRoutesByAccessible
} from '../../../utils/routes';
import { privateRoutes } from '../../../router/routes';
import { useNavigate } from 'react-router-dom';

type MobileLayoutProps = {
  entryPathname: string;
  children: React.ReactNode;
};

const MobileLayout: React.FC<MobileLayoutProps> = ({ entryPathname, children }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const { user } = useAuthContext();
  const { setUserStatuses } = useUsersContext();
  const { setGroups, setGroupsCount, setGroupsLoading } = useGroupsContext();

  /*
    Запросы, необходимые для нормального редактирования данных авторизованного пользователя
  */
  useEffect(() => {
    if (!user) {
      console.log('!user');
      return;
    }
    if (user.permissions.groups === 'W') {
      setGroupsLoading(true);
      getGroups(user.token, { limit: 999 })
        .then(({ payload }) => {
          setGroups(payload.groups);
          setGroupsCount(payload.count);
        })
        .catch((e) => {
          Toast.show({
            icon: 'fail',
            content: e.response?.data.error?.message || 'Произошла ошибка при загрузке групп'
          });
        })
        .finally(() => {
          setGroupsLoading(false);
        });
    }
    getUser(user.data.id, user.token)
      .then(({ payload }) => {
        setUserStatuses(payload.statuses);
      })
      .catch((e) => {
        Toast.show({
          icon: 'fail',
          content: e.response?.data.error?.message || 'Произошла ошибка при загрузке пользователей'
        });
      });
  }, []);

  const accessibleItems = React.useMemo(
    () => filterRoutesByAccessible(privateRoutes, user!.permissions, user!.properties),
    [user]
  );

  const onMenuItemClick: React.ComponentProps<typeof Menu>['onClick'] = (item) => {
    navigate(item.key);
  };

  return (
    <Layout
      style={{ minHeight: '100vh' }}
      className='mobile-main-layout'>
      <Layout>
        <Layout.Header
          style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
          <div className='menu-burger'>
            <Dropdown
              open={isDropdownVisible}
              onOpenChange={setIsDropdownVisible}
              trigger={['click']}
              menu={{
                theme: 'light',
                selectedKeys: [entryPathname],
                items: convertExtendedRoutesToMobileMenuItems(accessibleItems),
                onClick: onMenuItemClick
              }}>
              <button
                style={{ padding: '0 16px' }}
                className={
                  isDropdownVisible
                    ? 'hamburger-button hamburger-button--is-opened'
                    : 'hamburger-button'
                }>
                {HamburgerIcon}
              </button>
            </Dropdown>
          </div>
          <div className='user-section'>
            <UserInfoButton />
            <LogoutButton />
          </div>
        </Layout.Header>
        <Layout.Content>
          <div className='content__container'>{children}</div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export { MobileLayout };
