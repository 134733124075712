import React from 'react';
import { useList } from '../../hooks/useList';
import { Group, GroupsContextType } from '../../types/groups';

const initialState: GroupsContextType = {
  addGroup: () => {},
  deleteGroup: () => {},
  getGroup: () => ({
    id: 0,
    name: '',
    permissions: [],
    user_ids: [],
    properties: [],
    position: 0
  }),
  editGroup: () => {},
  setGroups: () => {},
  groups: [],
  groupsLoading: false,
  setGroupsLoading: () => {},
  groupsCount: 0,
  setGroupsCount: () => {}
};

const GroupsContext = React.createContext<GroupsContextType>(initialState);
const useGroupsContext = () => React.useContext(GroupsContext);

const GroupsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [list, setList, { get, add, remove, edit }] = useList<Group>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(0);

  const groupsList = React.useMemo<GroupsContextType>(
    () => ({
      groups: list,
      getGroup: get,
      addGroup: add,
      deleteGroup: remove,
      editGroup: edit,
      setGroups: setList,
      groupsLoading: loading,
      setGroupsLoading: setLoading,
      groupsCount: count,
      setGroupsCount: setCount
    }),
    [
      list,
      loading,
      count
    ]
  );

  return (
    <GroupsContext.Provider value={groupsList}>
      {children}
    </GroupsContext.Provider>
  );
};

export { useGroupsContext, GroupsContextProvider };
