import React from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { logout } from '../../../api/refactor/auth';
import { useAuthContext } from '../../../context/AuthContext';
import { showError } from '../../../utils/showError';

const LogoutButton: React.FC = () => {
  const { setUser } = useAuthContext();

  const onLogout = () => {
    logout()
      .then(() => {
        setUser(undefined);
      })
      .catch((e) => {
        showError(e);
      });
  };

  return (
    <Button
      className='user-section__button'
      type='ghost'
      icon={<LogoutOutlined style={{ fontSize: 28 }} onClick={onLogout} />}
    />
  );
};

export { LogoutButton };
