import { Typography } from 'antd';
import React from 'react';

const Outcast: React.FC = () => {
  const { Title, Text } = Typography;

  return (
    <>
      <Title level={3}>
        Уведомление
      </Title>
      <Text style={{fontSize: 16}}>
        Вы авторизовались как пользователь, у которого пуст список должностей.<br />
        Пожалуйста, обратитесь к администратору для назначения Вам надлежащей должности в системе.
      </Text>
    </>
  );
};

export { Outcast };
