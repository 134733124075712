import './PhoneInput.css';
import { useState, useEffect } from 'react';
import { Input, Select } from 'antd';
import { decoratePhone, removeNonDigitalChars } from '../../utils/defineFieldFormats';
import { Client } from '../../types/clients';

const defaultCode = '7';
const codes = [defaultCode];
const minMobilePhoneLength = 11;

const definePhoneParts = (numPhone: Client['phone']) => {
  if (!numPhone) {
    return ['', ''];
  }
  const strValue = numPhone.toString();
  if (strValue.length === minMobilePhoneLength) {
    return [strValue.slice(0, 1), strValue.slice(1)];
  }
  if (strValue.length === minMobilePhoneLength - 1) {
    return [defaultCode, strValue];
  }
  return ['', strValue];
};

type PhoneInputType = {
  phoneState?: [Client['phone'], React.Dispatch<React.SetStateAction<Client['phone']>>];
  isDisabled?: boolean;
  value?: Client['phone'];
  onChange?: (value: Client['phone']) => void;
};

const PhoneInput: React.FC<PhoneInputType> = ({ phoneState, isDisabled, value, onChange }) => {
  const [phonePart, setPhonePart] = useState<string>('');
  const [codePart, setCodePart] = useState<string>('');

  const changeOutputValue = (code: string, phone: string) => {
    const outputValue = Number(`${code}${phone}`) || null;
    if (phoneState) {
      const [_, setPhone] = phoneState;
      setPhone(outputValue);
      return;
    }
    onChange?.(outputValue);
  };

  const onPhoneChange = ({ target }: { target: HTMLInputElement }) => {
    const purifiedPhone = removeNonDigitalChars(target.value);
    const [code, phone] = definePhoneParts(Number(purifiedPhone));
    setCodePart(code);
    setPhonePart(phone);
    changeOutputValue(code, phone);
  };

  const onCountryCodeChange = (value: string) => {
    setCodePart(value);
    changeOutputValue(value, phonePart);
  };

  useEffect(() => {
    if (value || phoneState) {
      const [code, phone] = definePhoneParts(value || phoneState?.[0] || null);
      setCodePart(code);
      setPhonePart(phone);
    }
  }, [value, phoneState]);

  return (
    <Input.Group
      style={{ display: 'flex' }}
      compact>
      {phonePart?.length + 1 === minMobilePhoneLength && (
        <Select
          style={{
            backgroundColor: 'none !important'
          }}
          value={codePart}
          onChange={onCountryCodeChange}
          disabled={isDisabled}>
          {codes.map((code) => (
            <Select.Option
              key={code}
              value={code}
              children={`+${code}`}
            />
          ))}
        </Select>
      )}
      <Input
        maxLength={15}
        value={decoratePhone(phonePart)}
        onChange={onPhoneChange}
        disabled={isDisabled}
        allowClear={true}
      />
    </Input.Group>
  );
};

export { PhoneInput };
