import React from 'react';
import { useList } from '../../hooks/useList';
import { Managers } from '../../types/clients';
import {
  Statuses,
  Ticket,
  TicketRatings,
  TicketTypes
} from '../../types/tickets';
import { TicketsContextType } from '../../types/tickets';
import { Users } from '../../types/users';

const TicketsContext = React.createContext<TicketsContextType>(null!);
const useTicketsContext = () => React.useContext(TicketsContext);

const TicketsContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [list, setList, { get, add, remove, edit }] = useList<Ticket>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(0);
  const [statuses, setStatuses] = React.useState<Statuses>();
  const [ratings, setRatings] = React.useState<TicketRatings>();
  const [types, setTypes] = React.useState<TicketTypes>();
  const [operators, setOperators] = React.useState<Users>();
  const [managers, setManagers] = React.useState<Managers>();

  const ticketsList = React.useMemo<TicketsContextType>(
    () => ({
      addTicket: add,
      deleteTicket: remove,
      editTicket: edit,
      getTicket: get,
      tickets: list,
      ticketsLoading: loading,
      setTickets: setList,
      setTicketsLoading: setLoading,
      setTicketsCount: setCount,
      ticketsCount: count,
      statuses,
      setStatuses,
      ratings,
      setRatings,
      types,
      setTypes,
      operators,
      setOperators,
      setManagers,
      managers
    }),
    [loading, list, count, statuses, ratings, types, operators, managers]
  );

  return (
    <TicketsContext.Provider value={ticketsList}>
      {children}
    </TicketsContext.Provider>
  );
};

export { TicketsContextProvider, useTicketsContext };
