import React from 'react';
import { useList } from '../../hooks/useList';
import { Issue, IssuesContextType } from '../../types/issues';

const initialState: IssuesContextType = {
  addIssue: () => {},
  removeIssue: () => {},
  getIssue: () => undefined,
  editIssue: () => {},
  setIssues: () => {},
  issues: [],
  issuesLoading: false,
  setIssuesLoading: () => {},
  issuesCount: 0,
  setIssuesCount: () => {}
};

const IssuesContext = React.createContext<IssuesContextType>(initialState);
const useIssuesContext = () => React.useContext(IssuesContext);

const IssuesContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [list, setList, { get, add, remove, edit }] = useList<Issue>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(0);

  const issuesList = React.useMemo<IssuesContextType>(
    () => ({
      issues: list,
      getIssue: get,
      addIssue: add,
      removeIssue: remove,
      editIssue: edit,
      setIssues: setList,
      issuesLoading: loading,
      setIssuesLoading: setLoading,
      issuesCount: count,
      setIssuesCount: setCount
    }),
    [
      list,
      setList,
      get,
      add,
      remove,
      edit,
      loading,
      setLoading,
      count,
      setCount
    ]
  );

  return (
    <IssuesContext.Provider value={issuesList}>
      {children}
    </IssuesContext.Provider>
  );
};

export { IssuesContextProvider, useIssuesContext };
