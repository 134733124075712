import {
  CreateEntityResponse,
  DeleteEntityResponse,
  GetUsersResponse,
  UpdateEntityResponse
} from '../types/server';
import { GetUserResponse, ResetUsersResponse } from '../types/server/Response';
import { User } from '../types/users';
import { megaRequest } from './megaRequest';

const url = '/api/users/';

export const getUsers = (key: string, params?: any) =>
  megaRequest<GetUsersResponse>({
    type: 'GET',
    url: `${url}`,
    config: { headers: { 'Auth-key': key }, params }
  });

export const getUser = (id: number, key: string) =>
  megaRequest<GetUserResponse>({
    type: 'GET',
    url: `${url}${id}`,
    config: { headers: { 'Auth-key': key } }
  });

export const createUser = (userData: User, key: string) =>
  megaRequest<CreateEntityResponse<'id'>>({
    type: 'POST',
    url,
    config: { headers: { 'Auth-key': key } },
    data: userData,
    isNewFormat: true
  });

export const updateUser = (id: number, changeFields: Partial<User>, key: string) => {
  return megaRequest<UpdateEntityResponse>({
    type: 'UPDATE',
    url: `${url}${id}`,
    config: { headers: { 'Auth-key': key } },
    data: changeFields,
    isNewFormat: true
  });
};
export const deleteUser = (id: number, key: string) =>
  megaRequest<DeleteEntityResponse>({
    type: 'DELETE',
    url: `${url}${id}`,
    config: { headers: { 'Auth-key': key } }
  });

export const resetUsers = (key: string) =>
  megaRequest<ResetUsersResponse>({
    type: 'POST',
    url: `${url}all`,
    config: { headers: { 'Auth-key': key } }
  });
