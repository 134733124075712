import React from 'react';
import { useList } from '../../hooks/useList';
import { Event, EventsContextType } from '../../types/events';
import { StatusesType } from '../../types/clients';

const EventsContext = React.createContext<EventsContextType>({} as EventsContextType);
const useEventsContext = () => React.useContext(EventsContext);

const EventsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [
    events,
    setEvents,
    {
      get: getEvent,
      add: addEvent,
      remove: deleteEvent,
      edit: editEvent
    }
  ] = useList<Event>();
  const [statuses, setStatuses] = React.useState<StatusesType | undefined>(undefined);
  const [isStatusesLoaded, setIsStatusesLoaded] = React.useState<boolean>(false);
  const [types, setTypes] = React.useState<any>(undefined);
  const [eventsLoading, setEventsLoading] = React.useState<boolean>(true);

  const eventsList = React.useMemo<EventsContextType>(
    () => ({
      events,
      getEvent,
      addEvent,
      deleteEvent,
      editEvent,
      setEvents,
      eventsLoading,
      setEventsLoading,
      statuses,
      setStatuses,
      isStatusesLoaded,
      setIsStatusesLoaded,
      types,
      setTypes
    }),
    [
      types,
      events,
      statuses,
      eventsLoading,
      isStatusesLoaded
    ]
  );

  return (
    <EventsContext.Provider value={eventsList}>
      {children}
    </EventsContext.Provider>
  );
};

export { EventsContextProvider, useEventsContext };
