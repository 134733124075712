import { useEffect, useMemo, useState } from 'react';
import { Input } from 'antd';
import { trimPhrazeWithSingleSpace } from '../../utils/defineFieldFormats';
import { TextAreaProps } from 'antd/lib/input';

interface InputFreeTextType {
  disableTrimming?: boolean;
  isTextArea?: boolean;
  isDisabled?: boolean;
  autoFocus?: boolean;
  autoSize?: TextAreaProps['autoSize'];
  allowClear?: boolean;
  rows?: number;
  value?: string | number;
  placeholder?: string;
  name?: string;
  onChange?: (value?: string) => void;
}

const { TextArea } = Input;

//c бэка продакт-коды прилетают как тайпоф намбер, темпорарили фиксед зере
const fixValue = (_val: string | number | undefined) => {
  switch (typeof _val) {
    case 'string':
      return _val;
    case 'undefined':
      return undefined;
    default:
      return _val.toString();
  }
};

const InputFreeText: React.FC<InputFreeTextType> = ({
  disableTrimming,
  isTextArea,
  isDisabled,
  autoFocus,
  autoSize,
  allowClear,
  rows,
  value,
  placeholder,
  name,
  onChange
}) => {
  const [text, setText] = useState<string | undefined>();

  const onTextChange = (_value: string | undefined) => {
    const _text = !disableTrimming ? trimPhrazeWithSingleSpace(_value) : _value;

    setText(_text);
    onChange?.(_text?.trimEnd());
  };

  useEffect(() => {
    if (value) {
      setText(fixValue(value));
    }
  }, [value]);

  const _inputTextArea = useMemo(
    () => (
      <TextArea
        name={name}
        placeholder={placeholder}
        disabled={isDisabled}
        value={text}
        rows={rows}
        autoFocus={autoFocus}
        autoSize={autoSize}
        allowClear={allowClear}
        onChange={({ target }) => {
          onTextChange(target.value);
        }}
      />
    ),
    [text]
  );

  const _inputSimple = useMemo(
    () => (
      <Input
        placeholder={placeholder}
        disabled={isDisabled}
        value={text}
        autoFocus={autoFocus}
        allowClear={allowClear}
        onChange={({ target }) => {
          onTextChange(target.value);
        }}
      />
    ),
    [text]
  );

  return !isTextArea ? _inputSimple : _inputTextArea;
};

export { InputFreeText };
