import React from 'react';

const useList = <T>(
  initialState?: T[]
): [
  any[],
  React.Dispatch<React.SetStateAction<any[]>>,
  {
    get: (id: string | number) => T;
    add: (item: T) => void;
    remove: (id: string | number) => void;
    edit: (id: string | number, data: T) => void;
  }
] => {
  const [list, setList] = React.useState<any[]>(initialState || []);

  const add = (item: T): void => {
    setList((prev) => [...prev, { ...item }]);
  };

  const get = (id: string | number): T => list.find((item) => item.id === id);

  const remove = (id: string | number): void => {
    setList((prev) => prev.filter((item) => item.id !== id));
  };

  const edit = (id: string | number | number, data: T): void => {
    setList((prev) => prev.map((item) => (item.id === id ? { ...item, ...data } : item)));
  };

  return [list, setList, { get, add, remove, edit }];
};

export { useList };
