import React from 'react';
import { useList } from '../../hooks/useList';
import {
  Client,
  ClientsContextType,
  StatusesType,
  Managers
} from '../../types/clients';

const ClientsContext = React.createContext<ClientsContextType>({} as ClientsContextType);
const useClientsContext = () => React.useContext(ClientsContext);

const ClientsContextProvider = ({
  children
}: { 
  children: React.ReactNode;
}) => {
  const [list, setList, { get, add, remove, edit }] = useList<Client>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [managers, setManagers] = React.useState<Managers>([]);
  const [count, setCount] = React.useState<number>(0);
  const [statuses, setStatuses] = React.useState<StatusesType>({});
  const [isClientCreated, setIsClientCreated] = React.useState<boolean>(false);

  const clientsList = React.useMemo<ClientsContextType>(
    () => ({
      clients: list,
      getClient: get,
      addClient: add,
      deleteClient: remove,
      editClient: edit,
      setClients: setList,
      clientsLoading: loading,
      setClientsLoading: setLoading,
      clientsCount: count,
      setClientsCount: setCount,
      managers: managers,
      setManagers: setManagers,
      clientsStatuses: statuses,
      setClientsStatuses: setStatuses,
      isClientCreated: isClientCreated,
      setIsClientCreated: setIsClientCreated
    }),
    [
      list,
      loading,
      count,
      managers,
      statuses,
      isClientCreated
    ]
  );

  return (
    <ClientsContext.Provider value={clientsList}>
      {children}
    </ClientsContext.Provider>
  );
};

export { ClientsContextProvider, useClientsContext };
