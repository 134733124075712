import React from 'react';
import { useSchedules } from '../../hooks/useSchedules';
import { SchedulesContextType } from '../../types/schedules';

const initialState: SchedulesContextType = {
  addSchedule: () => {},
  removeSchedule: () => {},
  getSchedule: () => undefined,
  editSchedule: () => {},
  setSchedules: () => {},
  schedules: {},
  schedulesLoading: false,
  setSchedulesLoading: () => {}
};

const SchedulesContext =
  React.createContext<SchedulesContextType>(initialState);
const useSchedulesContext = () => React.useContext(SchedulesContext);

const SchedulesContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const {
    addSchedule,
    removeSchedule,
    editSchedule,
    getSchedule,
    schedules,
    setSchedules
  } = useSchedules();

  const schedulesList = React.useMemo<SchedulesContextType>(
    () => ({
      addSchedule: addSchedule,
      removeSchedule: removeSchedule,
      editSchedule: editSchedule,
      getSchedule: getSchedule,
      schedules: schedules,
      schedulesLoading: loading,
      setSchedules: setSchedules,
      setSchedulesLoading: setLoading
    }),
    [
      loading,
      setLoading,
      addSchedule,
      removeSchedule,
      editSchedule,
      getSchedule,
      schedules,
      setSchedules
    ]
  );

  return (
    <SchedulesContext.Provider value={schedulesList}>
      {children}
    </SchedulesContext.Provider>
  );
};

export { SchedulesContextProvider, useSchedulesContext };
