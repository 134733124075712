import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/', { replace: true });
  };

  return (
    <Result
      status='404'
      title='404'
      subTitle='Страница не найдена.'
      extra={
        <Button type='primary' onClick={onClick}>
          Вернуться
        </Button>
      }
    />
  );
};

export { NotFound };
