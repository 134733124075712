import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isoWeek from 'dayjs/plugin/isoWeek';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IConfigurator } from './interface';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

class DayjsConfigurator implements IConfigurator {
  public async setup(): Promise<void> {
    dayjs.extend(customParseFormat);
    dayjs.extend(advancedFormat);
    dayjs.extend(weekday);
    dayjs.extend(localeData);
    dayjs.extend(weekOfYear);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(isoWeek);
    dayjs.extend(isBetween);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(duration);
    dayjs.extend(relativeTime);
    dayjs.locale('ru');
  }
}

export const dayjsConfigurator = new DayjsConfigurator();
