export const API = {
  STATUS: '/',
  RATINGS: '/ratings',
  LOGIN: '/login',
  LOGOUT: '/logout',
  NOT_FOUND: '/404',
  USERS: '/users',
  GROUPS: '/groups',
  CLIENTS: '/clients',
  SCHEDULES: '/schedules',
  CALLS: '/calls',
  CALLBACK_CODES: '/callback-codes',
  TICKETS: '/tickets',
  PRODUCTS: '/products',
  TOOTH_ZONES: '/tooth-zones',
  SOLUTIONS: '/solutions',
  ISSUES: '/issues',
  EVENTS: '/receptions',
  ME: '/me',
  PLANS: '/plans',
  CANCELED_RECEPTIONS: '/canceled_receptions',
  DOCTORS: '/doctors',
  DOCTOR_GROUPS: '/doctor_groups',
  DEPARTMENTS: '/departments',
  SNAPSHOTS: '/xrays',
  MESSAGES: '/messages',
  DOCUMENTS: '/documents',
  DOCUMENTS_TEMPLATES: '/documents/templates',
  VARIABLES: '/documents/vars'
} as const;
