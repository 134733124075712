import { Rule } from "antd/lib/form";

export const RULE_NAME_PARTS: Rule = {
  type: 'string',
  min: 2,
  max: 22,
  message: 'Длина - от 2 до 22 символов',
  warningOnly: true
};
export const RULE_REQUIRED: Rule = {
  required: true,
  message: 'Заполните это поле'
};
export const RULE_RU_INPUT: Rule = {
  pattern: /[\-\u0401\u0451\u0410-\u044f]+/g,
  message: 'Недопустимый язык ввода',
  warningOnly: true
};
export const RULE_VALID_DATE: Rule = {
  pattern: /\d{2}\-\d{2}\-\d{4}/,
  message: 'Заполните дату полностью',
  warningOnly: true
};
