import './HamburgerIcon.css';

const HamburgerIcon= (
  <svg className='hamburger'>
    <line
      x1='0'
      y1='50%'
      x2='100%'
      y2='50%'
      className='hamburger__bar hamburger__bar--top'
    />
    <line
      x1='0'
      y1='50%'
      x2='100%'
      y2='50%'
      className='hamburger__bar hamburger__bar--mid'
    />
    <line
      x1='0'
      y1='50%'
      x2='100%'
      y2='50%'
      className='hamburger__bar hamburger__bar--bot'
    />
  </svg>
);

export { HamburgerIcon };
