import axios, { AxiosRequestConfig } from 'axios';
import { AuthUser } from '../../../types/auth';

const instance = axios.create({
  baseURL: '/api'
});

instance.interceptors.request.use((config) => {
  const userJson = localStorage.getItem('user');
  const user: AuthUser = userJson && JSON.parse(userJson);

  if (user) {
    config.headers['Auth-key'] = user.token;
  }

  return config;
});

export const api = {
  get: <T>(url: string, params?: object, config?: AxiosRequestConfig) =>
    instance
      .get<T>(url, {
        ...config,
        params
      })
      .then((res) => res.data),
  post: <T>(url: string, data: any, config?: AxiosRequestConfig) =>
    instance.post<T>(url, data, config).then((res) => res.data),
  delete: <T>(url: string, config?: AxiosRequestConfig) =>
    instance.delete<T>(url, config).then((res) => res.data)
};
