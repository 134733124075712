import React from 'react';
import { useList } from '../../hooks/useList';
import { Department, DepartmentsContextType } from '../../types/departments';

const DepartmentsContext = React.createContext<DepartmentsContextType>({
  addDepartment: () => {},
  deleteDepartment: () => {},
  getDepartment: () => undefined,
  editDepartment: () => {},
  setDepartments: () => {},
  departments: [],
  departmentsLoading: false,
  setDepartmentsLoading: () => {},
  departmentsCount: 0,
  setDepartmentsCount: () => {}
});
const useDepartmentsContext = () => React.useContext(DepartmentsContext);

const DepartmentsContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [list, setList, { get, add, remove, edit }] = useList<Department>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(0);

  const departmentsList = React.useMemo<DepartmentsContextType>(
    () => ({
      departments: list,
      getDepartment: get,
      addDepartment: add,
      deleteDepartment: remove,
      editDepartment: edit,
      setDepartments: setList,
      departmentsLoading: loading,
      setDepartmentsLoading: setLoading,
      departmentsCount: count,
      setDepartmentsCount: setCount
    }),
    [
      list,
      setList,
      get,
      add,
      remove,
      edit,
      loading,
      setLoading,
      count,
      setCount
    ]
  );

  return (
    <DepartmentsContext.Provider value={departmentsList}>
      {children}
    </DepartmentsContext.Provider>
  );
};

export { DepartmentsContextProvider, useDepartmentsContext };
