import { LoginResponse, LogoutResponse } from '../types/server';
import { megaRequest } from './megaRequest';

export const login = ({
  login,
  password,
  authKey,
}: {
  login: string;
  password: string;
  authKey: string;
}) => {
  return megaRequest<LoginResponse>({
    type: 'POST',
    url: '/api/login',
    data: { login, password },
    config: { headers: { 'Auth-key': authKey } },
  });
;}

export const logout = ({ authKey }: { authKey: string }) => {
  return megaRequest<LogoutResponse>({
    type: 'POST',
    url: '/api/logout',
    config: { headers: { 'Auth-key': authKey } },
  })
};

export const getAuthUserData = (key: string) => {
  return megaRequest<LoginResponse>({
    type: 'GET',
    url: `/api/`,
    config: { headers: { 'Auth-key': key } }
  });
};
