export const removeEmptyFields = (values: any) => {
  return Object.entries(values).reduce<any>((acc, [key, value]) => {
    if (!value) {
      return acc;
    }

    if (Array.isArray(value) && !value.length) {
      return acc;
    }

    if (typeof value === 'object' && !Object.keys(value).length) {
      return acc;
    }

    if (typeof value === 'string' && !value.length) {
      return acc;
    }

    acc[key] = value;

    return acc;
  }, {});
};
