import axios, { AxiosRequestConfig } from 'axios';

const megaRequest = <T>({
  url,
  type,
  data,
  config,
  isNewFormat
}: {
  url: string;
  type: 'GET' | 'POST' | 'DELETE' | 'UPDATE';
  data?: any;
  config?: AxiosRequestConfig;
  isNewFormat?: boolean;
}): Promise<T> => {
  switch (type) {
    case 'GET':
      return axios.get(url, config).then((res) => res.data);
    case 'POST':
      return axios.post(url, data, config).then((res) => res.data);
    case 'UPDATE':
      return axios
        .post(url, isNewFormat ? data : { change_fields: data }, config)
        .then((res) => res.data);
    case 'DELETE':
      return axios.delete(url, config).then((res) => res.data);
  }
};

export { megaRequest };
