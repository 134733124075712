import { MenuItemType, SubMenuType } from 'antd/es/menu/interface';
import { ExtendedRoute, ItemRoute } from '../router/types';
import { AuthProperties } from '../types/auth/Auth';
import { Permissions } from '../types/users/Users';
import { MenuProps } from 'antd';

const trustedFlags = ['W', 'R'];

const checkRouteItemAccessible = (
  route: ItemRoute,
  permissions: Permissions,
  properties: AuthProperties
) => {
  if (route.dependency === 'permissions') {
    return trustedFlags.includes(permissions[route.permissions]);
  } else {
    return (Object.entries(route.properties) as unknown as Entry<AuthProperties>[]).every(
      ([property, flag]) => properties[property] === flag
    );
  }
};

export const filterRoutesByAccessible = (
  routes: ExtendedRoute[],
  permissions: Permissions,
  properties: AuthProperties
) => {
  return routes.reduce<ExtendedRoute[]>((acc, route) => {
    if (route.type === 'item') {
      const isAccess = checkRouteItemAccessible(route, permissions, properties);
      if (isAccess) {
        acc.push(route);
      }
    } else {
      const filteredChildren = route.children.filter((ch) =>
        checkRouteItemAccessible(ch, permissions, properties)
      );
      if (!!filteredChildren.length) {
        acc.push({ ...route, children: filteredChildren });
      }
    }
    return acc;
  }, []);
};

export const getFirstRoutePathname = (routes: ExtendedRoute[]): string => {
  const route = routes[0];
  return route.type === 'item' ? route.path : getFirstRoutePathname(route.children);
};

export const convertExtendedRoutesToMenuItems = (
  routes: ExtendedRoute[]
): (MenuItemType | SubMenuType)[] => {
  return routes.map((item) =>
    item.type === 'item'
      ? { key: item.path, icon: item.icon, label: item.label }
      : {
          key: item.label,
          icon: item.icon,
          label: item.label,
          children: item.children.map((child) => ({
            key: child.path,
            icon: child.icon,
            label: child.label
          }))
        }
  );
};

export const convertExtendedRoutesToMobileMenuItems = (
  routes: ExtendedRoute[]
): MenuProps['items'] => {
  return routes.map((item) =>
    item.type === 'item'
      ? { key: item.path, icon: item.icon, label: item.label }
      : {
          type: 'group',
          key: item.label,
          label: item.label,
          children: item.children.map((child) => ({
            key: child.path,
            icon: child.icon,
            label: child.label
          }))
        }
  );
};
