import React from 'react';

import { useList } from '../../hooks/useList';
import { Call, CallsContextType } from '../../types/calls';
import { Users } from '../../types/users';

const initialState: CallsContextType = {
  addCall: () => {},
  calls: [],
  callsCount: 0,
  callsLoading: false,
  deleteCall: () => {},
  editCall: () => {},
  getCall: () => undefined,
  setCalls: () => {},
  setCallsCount: () => {},
  setCallsLoading: () => {},
  users: [],
  setUsers: () => {},
};

const CallsContext = React.createContext<CallsContextType>(initialState);
const useCallsContext = () => React.useContext(CallsContext);

const CallsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [list, setList, { get, add, remove, edit }] = useList<Call>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [users, setUsers] = React.useState<Users>([]);
  const [count, setCount] = React.useState<number>(0);

  const clientsList = React.useMemo<CallsContextType>(
    () => ({
      addCall: add,
      calls: list,
      callsCount: count,
      callsLoading: loading,
      deleteCall: remove,
      editCall: edit,
      getCall: get,
      setCalls: setList,
      setCallsCount: setCount,
      setCallsLoading: setLoading,
      users: users,
      setUsers: setUsers,
    }),
    [
      list,
      setList,
      get,
      add,
      remove,
      edit,
      loading,
      setLoading,
      count,
      setCount,
    ]
  );

  return (
    <CallsContext.Provider value={clientsList}>
      {children}
    </CallsContext.Provider>
  );
};

export { CallsContextProvider, useCallsContext };
