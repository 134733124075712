import { Button, Collapse, Result, Typography } from 'antd';
import React from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';

const ErrorHandlerContainer: React.FC = () => {
  const error = useRouteError() as Error;
  const navigate = useNavigate();

  const onRefresh = () => {
    navigate(0);
  };

  return (
    <Result
      status='warning'
      title='Произошла непредвиденная ошибка, и мы не можем отобразить содержимое'
      subTitle='Попробуйте обновить страницу'
      extra={
        <Button
          type='primary'
          onClick={onRefresh}>
          Обновить страницу
        </Button>
      }>
      <Collapse
        bordered={false}
        defaultActiveKey={['code']}>
        <Collapse.Panel
          key='code'
          header='Код ошибки'>
          <code>{error?.message ?? error}</code>
        </Collapse.Panel>
      </Collapse>
    </Result>
  );
};

export { ErrorHandlerContainer };
