import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { App } from './App';
import { GlobalProvider } from './context/Global';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import { dayjsConfigurator } from './config';

const setupConfiguration = async () => {
  await dayjsConfigurator.setup();
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  }
});

setupConfiguration().then(() => {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <ConfigProvider
          locale={ruRU}
          theme={{ token: { borderRadius: 0 } }}>
          <ReactQueryDevtools position='bottom-right' />
          <App />
        </ConfigProvider>
      </GlobalProvider>
    </QueryClientProvider>,
    document.getElementById('root')
  );
});
