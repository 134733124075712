import './UserForm.css';

import { useState } from 'react';
import { createUser, updateUser } from '../../../api/users';
import { getAuthUserData } from '../../../api/auth';
import { Form, Input, Modal, notification, Select } from 'antd';
import { useAuthContext } from '../../../context/AuthContext';
import { useUsersContext } from '../../../context/UsersContext';
import { User } from '../../../types/users';
import { showError } from '../../../utils/showError';
import { ModalType } from '../../../types/modals';
import { useGroupsContext } from '../../../context/GroupsContext';
import { removeEmptyFields } from '../../../utils/removeEmptyFields';
import { PhoneInput } from '../../../components/PhoneInput';
import { StatusesRadioGroup } from '../../../components/StatusesRadioGroup';
import { InputFreeText } from '../../../components/InputFreeText';
import { RULE_REQUIRED, RULE_NAME_PARTS } from '../../../constants/formValidationRules';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

const validateMessages = {
  string: {
    range: 'Допустима длина от ${min} до ${max} символов.'
  }
};

interface UsersModalType extends ModalType {
  currentUser: User | undefined;
  setIsUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
}

const UsersModal: React.FC<UsersModalType> = ({
  setVisible,
  open,
  mode,
  title,
  setIsUpdated,
  currentUser
}) => {
  const { groups, groupsLoading } = useGroupsContext();
  const { user, setUser } = useAuthContext();
  const { addUser, editUser, setUsersCount, userStatuses } = useUsersContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [changeFields, setChangeFields] = useState<Partial<User>>({});
  const [form] = Form.useForm();

  const isAuthUserOpened = user?.data.id === currentUser?.id;
  const canAuthUserEditGroups = user?.permissions.groups === 'W';
  const canAuthUserEditUsers = user?.permissions.users === 'W';
  const isFormEditable = isAuthUserOpened || canAuthUserEditUsers;

  const selectableGroups = canAuthUserEditGroups ? groups : currentUser?.groups;

  const onCreate = () => {
    if (user) {
      const newUser = { ...removeEmptyFields(changeFields) };

      setLoading(true);
      createUser(newUser, user.token)
        .then(({ payload }) => {
          const { id: newUserId } = payload;
          const { groups: groupsIds } = newUser;

          const newUserGroups =
            groupsIds?.map((groupId: number) => groups.find(({ id }) => id === groupId)) || [];
          const displayedUser = Object.assign(newUser, {
            groups: newUserGroups,
            id: newUserId,
            status: 'enabled',
            password: undefined
          });
          addUser(displayedUser);
          setUsersCount((prevState) => prevState + 1);
          setVisible(false);
          notification.success({ message: 'Пользователь создан' });
        })
        .catch((e) => {
          showError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onEdit = () => {
    if (!Object.keys(changeFields).length) {
      notification.info({ message: 'Ничего не было изменено' });
      onCancel();
      return;
    }
    if (user && currentUser) {
      setLoading(true);
      updateUser(currentUser.id, changeFields, user.token)
        .then(({ payload }) => {
          const { user: data } = payload;
          const { id } = data;

          if (isAuthUserOpened) {
            const { token } = user;
            getAuthUserData(token).then(({ payload }) => {
              const { user: data } = payload;
              const { properties, permissions } = data;
              setUser({ data, properties, permissions, token });
            });
          }
          editUser(id, data);
          setLoading(false);
          setIsUpdated?.(true);
          setVisible(false);
          notification.success({ message: 'Данные пользователя изменены' });
        })
        .catch((e) => {
          showError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onChangeFields = (chF: any): void => {
    const { value, name } = chF[0];
    const fieldName = name[0];

    setChangeFields((prevState: any) => ({
      ...prevState,
      [fieldName]: value
    }));
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onFinish = () => {
    form
      .validateFields()
      .then(() => {
        mode === 'create' ? onCreate() : onEdit();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      open={open}
      title={mode === 'create' ? 'Создание пользователя' : title}
      onOk={onFinish}
      okButtonProps={{ disabled: !isFormEditable, loading }}
      okText={mode === 'create' ? 'Создать' : 'Изменить'}
      onCancel={onCancel}
      cancelButtonProps={{ disabled: !isFormEditable }}
      cancelText='Закрыть'>
      <Form
        {...layout}
        form={form}
        name='user-form'
        validateMessages={validateMessages}
        onFieldsChange={onChangeFields}
        initialValues={
          currentUser
            ? {
                ...currentUser,
                groups: currentUser.groups?.map(({ id }) => id)
              }
            : {}
        }>
        <Form.Item
          name='login'
          key='login'
          label='Логин'
          rules={[RULE_NAME_PARTS, RULE_REQUIRED]}>
          <InputFreeText isDisabled={!isFormEditable} />
        </Form.Item>
        <Form.Item
          name='firstname'
          key='firstname'
          label='Имя'
          rules={[RULE_NAME_PARTS, RULE_REQUIRED]}>
          <InputFreeText isDisabled={!isFormEditable} />
        </Form.Item>
        <Form.Item
          name='lastname'
          key='lastname'
          label='Фамилия'
          rules={[RULE_NAME_PARTS, RULE_REQUIRED]}>
          <InputFreeText isDisabled={!isFormEditable} />
        </Form.Item>
        <Form.Item
          name='middlename'
          key='middlename'
          label='Отчество'
          rules={[RULE_NAME_PARTS]}>
          <InputFreeText isDisabled={!isFormEditable} />
        </Form.Item>
        <Form.Item
          name='password'
          key='password'
          label='Пароль'
          rules={[mode === 'create' ? RULE_REQUIRED : {}]}>
          <Input.Password
            placeholder='При изменении пароль будет обновлен'
            disabled={!isFormEditable}
          />
        </Form.Item>
        <Form.Item
          name='phone'
          key='phone'
          label='Телефон'>
          <PhoneInput isDisabled={!isFormEditable} />
        </Form.Item>
        <Form.Item
          name='groups'
          key='groups'
          label='Должности'>
          <Select
            disabled={!canAuthUserEditGroups}
            mode='multiple'
            loading={groupsLoading}>
            {selectableGroups?.map(({ id, name }) => (
              <Select.Option
                key={id}
                value={id}
                children={name}
              />
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='additional'
          key='additional'
          label='Информация'
          rules={[{ min: 0, max: 10000 }]}>
          <InputFreeText
            isTextArea
            isDisabled={!isFormEditable}
          />
        </Form.Item>
        {mode === 'edit' && (
          <Form.Item
            name='status'
            key='status'
            label='Статус'>
            <StatusesRadioGroup
              isDisabled={!isFormEditable}
              statuses={userStatuses}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export { UsersModal };
