import React from 'react';
import { useList } from '../../hooks/useList';
import { Plan, PlansContextType } from '../../types/plans';

const initialState: PlansContextType = {
  addPlan: () => {},
  deletePlan: () => {},
  getPlan: () => undefined,
  editPlan: () => {},
  setPlans: () => {},
  plans: [],
  plansLoading: false,
  setPlansLoading: () => {},
  plansCount: 0,
  setPlansCount: () => {}
};

const PlansContext = React.createContext<PlansContextType>(initialState);
const usePlansContext = () => React.useContext(PlansContext);

const PlansContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [list, setList, { get, add, remove, edit }] = useList<Plan>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(0);

  const plansList = React.useMemo<PlansContextType>(
    () => ({
      plans: list,
      getPlan: get,
      addPlan: add,
      deletePlan: remove,
      editPlan: edit,
      setPlans: setList,
      plansLoading: loading,
      setPlansLoading: setLoading,
      plansCount: count,
      setPlansCount: setCount
    }),
    [
      list,
      setList,
      get,
      add,
      remove,
      edit,
      loading,
      setLoading,
      count,
      setCount
    ]
  );

  return (
    <PlansContext.Provider value={plansList}>{children}</PlansContext.Provider>
  );
};

export { PlansContextProvider, usePlansContext };
