import React from 'react';
import { useList } from '../../hooks/useList';
import { ProductsContextType, QueryProduct } from '../../types/products';

const initialState: ProductsContextType = {
  addProduct: () => {},
  removeProduct: () => {},
  getProduct: () => undefined,
  editProduct: () => {},
  setProducts: () => {},
  products: [],
  productsLoading: false,
  setProductsLoading: () => {},
  productsCount: 0,
  setProductsCount: () => {}
};

const ProductsContext = React.createContext<ProductsContextType>(initialState);
const useProductsContext = () => React.useContext(ProductsContext);

const ProductsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [list, setList, { get, add, remove, edit }] = useList<QueryProduct>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(0);

  const productsList = React.useMemo<ProductsContextType>(
    () => ({
      products: list,
      getProduct: get,
      addProduct: add,
      removeProduct: remove,
      editProduct: edit,
      setProducts: setList,
      productsLoading: loading,
      setProductsLoading: setLoading,
      productsCount: count,
      setProductsCount: setCount
    }),
    [list, setList, get, add, remove, edit, loading, setLoading, count, setCount]
  );

  return <ProductsContext.Provider value={productsList}>{children}</ProductsContext.Provider>;
};

export { ProductsContextProvider, useProductsContext };
