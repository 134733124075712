import { Group } from '../types/groups';
import {
  CreateEntityResponse,
  DeleteEntityResponse,
  GetEntityResponse,
  GetGroupsResponse,
  UpdateEntityResponse
} from '../types/server';
import { megaRequest } from './megaRequest';

const url = '/api/groups/';

const getGroups = (key: string, params?: any) =>
  megaRequest<GetGroupsResponse>({
    type: 'GET',
    url,
    config: { headers: { 'Auth-key': key }, params }
  });

const getGroup = (id: number, key: string) =>
  megaRequest<GetEntityResponse<Group>>({
    type: 'GET',
    url: `${url}${id}`,
    config: { headers: { 'Auth-key': key } }
  });

const createGroup = (groupData: Group, key: string) =>
  megaRequest<CreateEntityResponse<'id'>>({
    type: 'POST',
    url,
    config: { headers: { 'Auth-key': key } },
    data: groupData
  });

const updateGroup = (id: number, changeFields: Group, key: string) =>
  megaRequest<UpdateEntityResponse>({
    type: 'UPDATE',
    url: `${url}${id}`,
    config: { headers: { 'Auth-key': key } },
    data: changeFields,
    isNewFormat: true
  });

const deleteGroup = (id: number, key: string) =>
  megaRequest<DeleteEntityResponse>({
    type: 'DELETE',
    url: `${url}${id}`,
    config: { headers: { 'Auth-key': key } }
  });

export { createGroup, deleteGroup, getGroup, getGroups, updateGroup };
