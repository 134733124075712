import './StatusesRadioGroup.css';
import React from 'react';

import { Radio, RadioChangeEvent } from 'antd';
import { StatusesType } from '../../types/clients';

type StatusesRadioGroupType = {
  isDisabled?: boolean;
  statuses: StatusesType;
  value?: string;
  onChange?: (value: string) => void;
};

const StatusesRadioGroup: React.FC<StatusesRadioGroupType> = ({
  isDisabled,
  statuses,
  value,
  onChange
}) => {
  const [status, setStatus] = React.useState<string | undefined>();

  const onChangeStatus = (e: RadioChangeEvent) => {
    const { value } = e.target;
    onChange?.(value);
    setStatus(value);
  };

  React.useEffect(() => {
    setStatus(value);
  }, [value]);

  return (
    <Radio.Group
      key='status'
      buttonStyle='solid'
      value={status}
      onChange={onChangeStatus}
      disabled={isDisabled}
      children={
        !statuses
          ? []
          : Object.entries(statuses).map(([key, value]) => (
              <Radio.Button
                key={key}
                value={key}
                className={`status-radio status-radio_${key}`}
                children={value}
              />
            ))
      }
    />
  );
};

export { StatusesRadioGroup };
