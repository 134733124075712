import { notification } from 'antd';
import { AxiosError } from 'axios';
import { ErrorResponse } from '../types/server/Response';
import { Toast } from 'antd-mobile';

export const showError = (e: AxiosError<ErrorResponse>) => {
  return notification.error({
    message: e.message,
    description: e.response?.data.error?.message || ''
  });
};

export const showMobileError = (e: AxiosError<ErrorResponse>, reason: string) => {
  return Toast.show({
    icon: 'fail',
    content: e.response?.data.error?.message || `Произошла ошибка при ${reason}` 
  });
};
