import { useLocation } from 'react-router-dom';
import { PATH_TITLES, ROUTES } from '../../constants';
import { isTouchDevice } from '../../utils/isTouchDevice';
import { DesktopLayout } from './DesktopLayout';
import { MobileLayout } from './MobileLayout';

type RouteType = Exclude<(typeof ROUTES)[keyof typeof ROUTES], '/login'>;

type MainLayoutProps = {
  children: React.ReactNode;
};

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { pathname } = useLocation();

  const matchedPathname = pathname.match(/(\/\w*)/g);
  const entryPathname = matchedPathname![0] as RouteType;

  const title = PATH_TITLES[entryPathname];

  return isTouchDevice() ? (
    <MobileLayout entryPathname={entryPathname}>{children}</MobileLayout>
  ) : (
    <DesktopLayout
      title={title}
      entryPathname={entryPathname}>
      {children}
    </DesktopLayout>
  );
};

export { MainLayout };
