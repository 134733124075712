import React from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';
import { ErrorHandlerContainer } from '../components/ErrorHandlerContainer';
import { MainLayout } from '../components/Layout';
import { ROUTES } from '../constants';
import { useAuthContext } from '../context/AuthContext';
import { NotFound } from '../pages/404/NotFound';
import { filterRoutesByAccessible, getFirstRoutePathname } from '../utils/routes';
import { privateRoutes, publicRoutes } from './routes';
import { Outcast } from '../pages/Outcast/Outcast';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

const Router = () => {
  const { user } = useAuthContext();

  const routes = React.useMemo(() => {
    if (user) {
      const filteredRoutes = filterRoutesByAccessible(
        privateRoutes,
        user.permissions,
        user.properties
      );

      const startPath = filteredRoutes.length
        ? getFirstRoutePathname(filteredRoutes)
        : ROUTES.OUTCAST;

      return [
        {
          element: (
            <ConfigProvider locale={ruRU}>
              <MainLayout>
                <React.Suspense fallback={<LoadingSpinner />}>
                  <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <Outlet />
                  </QueryParamProvider>
                </React.Suspense>
              </MainLayout>
            </ConfigProvider>
          ),
          children: [
            {
              errorElement: <ErrorHandlerContainer />,
              children: [
                ...filteredRoutes,
                { index: true, element: <Navigate to={startPath} /> },
                { path: ROUTES.LOGIN, element: <Navigate to='/' /> },
                { path: ROUTES.OUTCAST, element: <Outcast /> },
                { path: ROUTES.NOT_FOUND, element: <NotFound /> },
                { path: '*', element: <Navigate to={ROUTES.NOT_FOUND} /> }
              ]
            }
          ]
        }
      ];
    }

    return publicRoutes;
  }, [user]);

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

export { Router };
