import React from 'react';
import { useList } from '../../hooks/useList';
import { ToothZone, ToothZonesContextType } from '../../types/toothZones';

const initialState: ToothZonesContextType = {
  addToothZone: () => {},
  removeToothZone: () => {},
  getToothZone: () => undefined,
  editToothZone: () => {},
  setToothZones: () => {},
  toothZones: [],
  toothZonesLoading: false,
  setToothZonesLoading: () => {},
  toothZonesCount: 0,
  setToothZonesCount: () => {}
};

const ToothZonesContext =
  React.createContext<ToothZonesContextType>(initialState);
const useToothZonesContext = () => React.useContext(ToothZonesContext);

const ToothZonesContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [list, setList, { get, add, remove, edit }] = useList<ToothZone>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(0);

  const toothZonesList = React.useMemo<ToothZonesContextType>(
    () => ({
      toothZones: list,
      getToothZone: get,
      addToothZone: add,
      removeToothZone: remove,
      editToothZone: edit,
      setToothZones: setList,
      toothZonesLoading: loading,
      setToothZonesLoading: setLoading,
      toothZonesCount: count,
      setToothZonesCount: setCount
    }),
    [
      list,
      setList,
      get,
      add,
      remove,
      edit,
      loading,
      setLoading,
      count,
      setCount
    ]
  );

  return (
    <ToothZonesContext.Provider value={toothZonesList}>
      {children}
    </ToothZonesContext.Provider>
  );
};

export { ToothZonesContextProvider, useToothZonesContext };
