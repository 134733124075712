import { useState, useEffect } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { UsersModal } from '../../../pages/Users/UsersModal';
import { useAuthContext } from '../../../context/AuthContext';
import { User } from '../../../types/users';

const UserInfoButton = () => {
  const { user } = useAuthContext();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User>();
  const [title, setTitle] = useState<string>('');

  const onUserModalOpen = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (user) {
      const { lastname, firstname, middlename } = user.data;
      setTitle(`${lastname} ${firstname} ${middlename}`.trim());
      setCurrentUser(user.data);
    }
  }, [user]);

  return (
    <>
      <Button
        onClick={onUserModalOpen}
        className='user-section__button'
        type='ghost'
        icon={<SettingOutlined style={{ fontSize: 28 }} />}
      />
      {isModalVisible && (
        <UsersModal
          mode='edit'
          title={title}
          currentUser={currentUser}
          open={isModalVisible}
          setVisible={setIsModalVisible}
        />
      )}
    </>
  );
};

export { UserInfoButton };
