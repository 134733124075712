import React from 'react';
import {
  TeamOutlined,
  UserOutlined,
  IdcardOutlined,
  TableOutlined,
  CustomerServiceOutlined,
  PhoneOutlined,
  WhatsAppOutlined,
  UnorderedListOutlined,
  IssuesCloseOutlined,
  SmileOutlined,
  ReconciliationOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  HistoryOutlined,
  AppstoreOutlined,
  ScheduleOutlined,
  ToolOutlined,
  TagOutlined,
  FileAddOutlined,
  UsergroupAddOutlined,
  MessageOutlined,
  PicRightOutlined
} from '@ant-design/icons';
import { FaTooth } from 'react-icons/fa';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { AiOutlineFileText, AiOutlineCode } from 'react-icons/ai';
import { ROUTES } from './../constants/routes';
import { isTouchDevice } from '../utils/isTouchDevice';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { PATH_TITLES } from '../constants';
import { ExtendedRoute } from './types';
import { ErrorHandlerContainer } from '../components/ErrorHandlerContainer';

const LoginPage = React.lazy(() => import('./../pages/Login'));
const UsersPage = React.lazy(() => import('./../pages/Users'));
const GroupsPage = React.lazy(() => import('./../pages/Groups'));
const ClientsPage = React.lazy(() => import('./../pages/Clients'));
const SchedulesPage = React.lazy(() => import('./../pages/Schedules'));
const CallsPage = React.lazy(() => import('./../pages/Calls'));
const TicketsPage = React.lazy(() => import('./../pages/Tickets'));
const ProductsPage = React.lazy(() => import('./../pages/Products'));
const ToothZonesPage = React.lazy(() => import('./../pages/ToothZones'));
const SolutionsPage = React.lazy(() => import('./../pages/Solutions'));
const IssuesPage = React.lazy(() => import('./../pages/Issues'));
const EventsPage = React.lazy(() => import('./../pages/Events'));
const EventsMobilePage = React.lazy(() => import('./../pages/EventsMobile'));
const MePage = React.lazy(() => import('./../pages/Me'));
const PlansPage = React.lazy(() => import('./../pages/Plans'));
const RevisionsPage = React.lazy(() => import('../pages/Revisions'));
const DepartmentsPage = React.lazy(() => import('./../pages/Departments'));
const CallbackCodesPage = React.lazy(() => import('./../pages/CallbackCodes'));
const SnapshotsPage = React.lazy(() => import('./../pages/Snapshots'));
const MessagesPage = React.lazy(() => import('./../pages/Messages'));
const VariablesPage = React.lazy(() => import('./../pages/Variables'));
const DocumentTemplatesPage = React.lazy(() => import('./../pages/DocumentTemplates'));

const publicRoutes: RouteObject[] = [
  {
    errorElement: <ErrorHandlerContainer />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: (
          <React.Suspense fallback={<LoadingSpinner />}>
            <LoginPage />
          </React.Suspense>
        )
      },
      {
        path: '*',
        element: <Navigate to={ROUTES.LOGIN} />
      }
    ]
  }
];

const privateRoutes: ExtendedRoute[] = [
  {
    type: 'item',
    label: PATH_TITLES[ROUTES.ME],
    path: ROUTES.ME,
    dependency: 'properties',
    properties: { doctors: 'Y' },
    icon: <SmileOutlined />,
    element: <MePage />
  },
  {
    type: 'submenu',
    label: 'Расписание',
    icon: <ScheduleOutlined />,
    element: <Outlet />,
    children: [
      {
        type: 'item',
        path: ROUTES.EVENTS,
        label: PATH_TITLES[ROUTES.EVENTS],
        dependency: 'permissions',
        permissions: 'receptions',
        icon: <ClockCircleOutlined />,
        element: isTouchDevice() ? <EventsMobilePage /> : <EventsPage />,
        children: [
          {
            path: '*',
            element: <Outlet />
          }
        ]
      },
      {
        type: 'item',
        path: ROUTES.SCHEDULES,
        label: PATH_TITLES[ROUTES.SCHEDULES],
        dependency: 'permissions',
        permissions: 'schedules',
        icon: <TableOutlined />,
        element: <SchedulesPage />
      },
      {
        type: 'item',
        path: ROUTES.REVISIONS,
        label: PATH_TITLES[ROUTES.REVISIONS],
        dependency: 'permissions',
        permissions: 'receptions',
        icon: <HistoryOutlined />,
        element: <RevisionsPage />
      }
    ]
  },
  {
    type: 'submenu',
    label: 'Пациенты',
    icon: <IdcardOutlined />,
    element: <Outlet />,
    children: [
      {
        type: 'item',
        label: PATH_TITLES[ROUTES.CLIENTS],
        path: ROUTES.CLIENTS,
        dependency: 'permissions',
        permissions: 'clients',
        icon: <UsergroupAddOutlined />,
        element: <ClientsPage />
      },
      {
        type: 'item',
        label: PATH_TITLES[ROUTES.SNAPSHOTS],
        path: ROUTES.SNAPSHOTS,
        dependency: 'permissions',
        permissions: 'xrays',
        icon: <FileAddOutlined />,
        element: <SnapshotsPage />
      }
    ]
  },
  {
    type: 'submenu',
    label: 'Колл-центр',
    icon: <CustomerServiceOutlined />,
    element: <Outlet />,
    children: [
      {
        type: 'item',
        label: PATH_TITLES[ROUTES.CALLS],
        path: ROUTES.CALLS,
        dependency: 'permissions',
        permissions: 'calls',
        icon: <PhoneOutlined />,
        element: <CallsPage />
      },
      {
        type: 'item',
        label: PATH_TITLES[ROUTES.TICKETS],
        path: ROUTES.TICKETS,
        dependency: 'permissions',
        permissions: 'tickets',
        icon: <WhatsAppOutlined />,
        element: <TicketsPage />
      }
    ]
  },
  {
    type: 'item',
    label: PATH_TITLES[ROUTES.MESSAGES],
    path: ROUTES.MESSAGES,
    dependency: 'permissions',
    permissions: 'departments',
    icon: <MessageOutlined />,
    element: <MessagesPage />
  },
  {
    type: 'submenu',
    label: 'Мед-блок',
    icon: <AppstoreOutlined />,
    element: <Outlet />,
    children: [
      {
        type: 'item',
        label: PATH_TITLES[ROUTES.PLANS],
        path: ROUTES.PLANS,
        dependency: 'permissions',
        permissions: 'plans',
        icon: <ReconciliationOutlined />,
        element: <PlansPage />
      },
      {
        type: 'item',
        label: PATH_TITLES[ROUTES.TOOTH_ZONES],
        path: ROUTES.TOOTH_ZONES,
        dependency: 'permissions',
        permissions: 'tooth-zones',
        icon: <FaTooth />,
        element: <ToothZonesPage />
      },
      {
        type: 'item',
        label: PATH_TITLES[ROUTES.ISSUES],
        path: ROUTES.ISSUES,
        dependency: 'permissions',
        permissions: 'issues',
        icon: <ExclamationCircleOutlined />,
        element: <IssuesPage />
      },
      {
        type: 'item',
        label: PATH_TITLES[ROUTES.SOLUTIONS],
        path: ROUTES.SOLUTIONS,
        dependency: 'permissions',
        permissions: 'solutions',
        icon: <IssuesCloseOutlined />,
        element: <SolutionsPage />
      },
      {
        type: 'item',
        label: PATH_TITLES[ROUTES.PRODUCTS],
        path: ROUTES.PRODUCTS,
        dependency: 'permissions',
        permissions: 'products',
        icon: <UnorderedListOutlined />,
        element: <ProductsPage />
      },
      {
        type: 'item',
        label: PATH_TITLES[ROUTES.CALLBACK_CODES],
        path: ROUTES.CALLBACK_CODES,
        dependency: 'permissions',
        permissions: 'callback-codes',
        icon: <TagOutlined />,
        element: <CallbackCodesPage />
      }
    ]
  },
  {
    type: 'submenu',
    label: 'Документы',
    icon: <AiOutlineFileText />,
    element: <Outlet />,
    children: [
      {
        type: 'item',
        label: PATH_TITLES[ROUTES.VARIABLES],
        path: ROUTES.VARIABLES,
        dependency: 'permissions',
        permissions: 'documents',
        icon: <AiOutlineCode />,
        element: <VariablesPage />
      },
      {
        type: 'item',
        label: PATH_TITLES[ROUTES.DOCUMENTS_TEMPLATES],
        path: ROUTES.DOCUMENTS_TEMPLATES,
        dependency: 'permissions',
        permissions: 'documents',
        icon: <PicRightOutlined />,
        element: <DocumentTemplatesPage />
      }
    ]
  },
  {
    type: 'submenu',
    label: 'Администрирование',
    icon: <ToolOutlined />,
    element: <Outlet />,
    children: [
      {
        type: 'item',
        label: PATH_TITLES[ROUTES.USERS],
        path: ROUTES.USERS,
        dependency: 'permissions',
        permissions: 'users',
        icon: <UserOutlined />,
        element: <UsersPage />
      },
      {
        type: 'item',
        label: PATH_TITLES[ROUTES.GROUPS],
        path: ROUTES.GROUPS,
        dependency: 'permissions',
        permissions: 'groups',
        icon: <TeamOutlined />,
        element: <GroupsPage />
      },
      {
        type: 'item',
        label: PATH_TITLES[ROUTES.DEPARTMENTS],
        path: ROUTES.DEPARTMENTS,
        dependency: 'permissions',
        permissions: 'departments',
        icon: <HiOutlineUserGroup />,
        element: <DepartmentsPage />
      }
    ]
  }
];

export { publicRoutes, privateRoutes };
