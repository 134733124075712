import { AuthContextProvider } from '../AuthContext';
import { ClientsContextProvider } from '../ClientsContext';
import { GroupsContextProvider } from '../GroupsContext';
import { SchedulesContextProvider } from '../SchedulesContext';
import { UsersContextProvider } from '../UsersContext';
import { CallsContextProvider } from '../CallsContext';
import { TicketsContextProvider } from '../TicketsContext';
import { ProductsContextProvider } from '../ProductsContext';
import { ToothZonesContextProvider } from '../ToothZonesContext';
import { SolutionsContextProvider } from '../SolutionsContext';
import { IssuesContextProvider } from '../IssuesContext';
import { EventsContextProvider } from '../EventsContext';
import { PlansContextProvider } from '../PlansContext';
import { DepartmentsContextProvider } from '../DepartmentsContext';
import { CallbackCodesContextProvider } from '../CallbackCodesContext';

const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <AuthContextProvider>
      <UsersContextProvider>
        <GroupsContextProvider>
          <ClientsContextProvider>
            <SchedulesContextProvider>
              <CallsContextProvider>
                <TicketsContextProvider>
                  <ProductsContextProvider>
                    <ToothZonesContextProvider>
                      <SolutionsContextProvider>
                        <IssuesContextProvider>
                          <EventsContextProvider>
                            <PlansContextProvider>
                              <DepartmentsContextProvider>
                                <CallbackCodesContextProvider>
                                  {children}
                                </CallbackCodesContextProvider>
                              </DepartmentsContextProvider>
                            </PlansContextProvider>
                          </EventsContextProvider>
                        </IssuesContextProvider>
                      </SolutionsContextProvider>
                    </ToothZonesContextProvider>
                  </ProductsContextProvider>
                </TicketsContextProvider>
              </CallsContextProvider>
            </SchedulesContextProvider>
          </ClientsContextProvider>
        </GroupsContextProvider>
      </UsersContextProvider>
    </AuthContextProvider>
  );
};

export { GlobalProvider };
