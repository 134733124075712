import { User } from "../types/users";
import { Client, Manager } from "../types/clients";

type ReplaceCallbackType = (strValue: string) => [RegExp, string];

const definePhoneReplacer: ReplaceCallbackType = (strPhone) => {
  switch (strPhone.length) {
    case 4:  return [/(\d{2})/, '$1-'];
    case 5:  return [/(\d{1})(\d{2})/, '$1-$2-'];
    case 6:  return [/(\d{2})(\d{2})/, '$1-$2-'];
    case 7:  return [/(\d{3})(\d{2})/, '$1-$2-'];
    case 10: return [/(\d{3})(\d{3})(\d{2})/, '($1) $2-$3-'];
    case 11: return [/(\d{1})(\d{3})(\d{3})(\d{2})/, '+$1 ($2) $3-$4-'];
    default: return [/(\d*)/, '$1'];
  }
};

const defineDateReplacer: ReplaceCallbackType = (strDate) => {
  const { length: _l } = strDate;

  switch (true) {
    case _l < 3:  return [/(\d{1,2})/, '$1'];
    case _l < 5:  return [/(\d{2})(\d{1,2})/, '$1.$2'];
    default:  return [/(\d{2})(\d{2})(\d{1,})/, '$1.$2.$3'];
  }
};

const defineTimeReplacer: ReplaceCallbackType = (strTime) => {
  if (strTime.length <= 2) {
    return [/(\d{1,2})/, '$1'];
  } else if (strTime.length <= 4) {
    return [/(\d{2})(\d{1,2})/, '$1:$2'];
  } else {
    return [/(\d{2})(\d{2})(\d{1,2})/, '$1:$2:$3'];
  }
};


export const removeNonDigitalChars = (text?: string, divider?: string) => {
  return text?.split(/\D/).join(divider || '');
};

export const numberizePhone = (phone?: string): number => {
  return Number(removeNonDigitalChars(phone));
};

export const decorateField = (replacerCallback: ReplaceCallbackType, date?: string): string => {
  const cleanDate = removeNonDigitalChars(date);

  if (cleanDate) {
    const [regexp, replacer] = replacerCallback(cleanDate);
    return cleanDate.replace(regexp, replacer);
  }
  return '';
};

export const decoratePhone = (phone?: string | number | null): string => {
  return decorateField(definePhoneReplacer, String(phone))
};

export const decorateTime = (time?: string): string => {
  return decorateField(defineTimeReplacer, time)
};

export const decorateDate = (date?: string): string => {
  return decorateField(defineDateReplacer, date);
};

export const decorateBirthday = (birthday: string | undefined) => {
  if (!birthday) {
    return '';
  }
  return removeNonDigitalChars(birthday, '.');
};

export const trimSingleWord = (word?: string) => {
  return word ? word.toString().trim() : '';
};

export const trimPhrazeWithSingleSpace = (phrase?: string) => {
  return phrase
    ? phrase
      .replace(/^\s+/g, '')
      .replace(/\s{2,}/g, ' ')
    : '';
};

export const trimPhrazeTotal = (phrase?: string) => {
  return phrase
    ? trimPhrazeWithSingleSpace(phrase).trimEnd()
    : '';
};

export const extractPersonFullname = (
  person?: Client | User | Manager,
  isInitialsOnly?: true
) => {
  if (!person) {
    return '';
  }
  const { lastname, firstname, middlename } = person;
  return [lastname, firstname, middlename]
    .map((word, index) => isInitialsOnly && index && word ? `${word?.slice(0, 1)}.` : trimSingleWord(word))
    .join(' ');
};

export const translateEvent = (event: string) => {
  return event === 'cancel'
    ? 'Отмена'
    : event === 'create'
    ? 'Создание'
    : 'Изменение';
};
