import { ROUTES } from './routes';

export const PATH_TITLES = {
  [ROUTES.OUTCAST]: 'Информация для пользователя без должностей',
  [ROUTES.NOT_FOUND]: 'Страница не найдена',
  [ROUTES.USERS]: 'Пользователи',
  [ROUTES.GROUPS]: 'Должности',
  [ROUTES.CLIENTS]: 'Клиенты',
  [ROUTES.SCHEDULES]: 'Графики',
  [ROUTES.CALLS]: 'Звонки',
  [ROUTES.TICKETS]: 'Тикеты',
  [ROUTES.PRODUCTS]: 'Услуги',
  [ROUTES.TOOTH_ZONES]: 'Зоны зуба',
  [ROUTES.SOLUTIONS]: 'Решения',
  [ROUTES.ISSUES]: 'Проблемы',
  [ROUTES.EVENTS]: 'Расписание',
  [ROUTES.ME]: 'Личный кабинет',
  [ROUTES.PLANS]: 'Планы лечения',
  [ROUTES.REVISIONS]: 'История',
  [ROUTES.DEPARTMENTS]: 'Отделения',
  [ROUTES.CALLBACK_CODES]: 'Коды',
  [ROUTES.SNAPSHOTS]: 'Рентгенография',
  [ROUTES.MESSAGES]: 'Сообщения',
  [ROUTES.VARIABLES]: 'Переменные',
  [ROUTES.DOCUMENTS_TEMPLATES]: 'Шаблоны'
} as const;
