import React from 'react';
import { useList } from '../../hooks/useList';
import { User, UsersContextType } from '../../types/users';

const initialState: UsersContextType = {
  addUser: () => {},
  deleteUser: () => {},
  getUser: () => ({
    additional: '',
    firstname: '',
    id: 0,
    lastname: '',
    middlename: '',
    phone: null,
    permissions: {},
    status: ''
  }),
  editUser: () => {},
  setUsers: () => {},
  users: [],
  usersLoading: false,
  setUsersLoading: () => {},
  usersCount: 0,
  setUsersCount: () => {},
  userStatuses: {},
  setUserStatuses: () => {}
};

const UsersContext = React.createContext<UsersContextType>(initialState);
const useUsersContext = () => React.useContext(UsersContext);

const UsersContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [list, setList, { get, add, remove, edit }] = useList<User>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(0);
  const [statuses, setStatuses] = React.useState<{ [status: string]: string }>(
    {}
  );

  const usersList = React.useMemo<UsersContextType>(
    () => ({
      users: list,
      getUser: get,
      addUser: add,
      deleteUser: remove,
      editUser: edit,
      setUsers: setList,
      usersLoading: loading,
      setUsersLoading: setLoading,
      usersCount: count,
      setUsersCount: setCount,
      userStatuses: statuses,
      setUserStatuses: setStatuses
    }),
    [
      list,
      setList,
      get,
      add,
      remove,
      edit,
      loading,
      setLoading,
      count,
      setCount,
      statuses
    ]
  );

  return (
    <UsersContext.Provider value={usersList}>{children}</UsersContext.Provider>
  );
};

export { useUsersContext, UsersContextProvider };
