import React from 'react';
import { useList } from '../../hooks/useList';
import { Solution, SolutionsContextType } from '../../types/solutions';

const initialState: SolutionsContextType = {
  addSolution: () => {},
  removeSolution: () => {},
  getSolution: () => undefined,
  editSolution: () => {},
  setSolutions: () => {},
  solutions: [],
  solutionsLoading: false,
  setSolutionsLoading: () => {},
  solutionsCount: 0,
  setSolutionsCount: () => {}
};

const SolutionsContext =
  React.createContext<SolutionsContextType>(initialState);
const useSolutionsContext = () => React.useContext(SolutionsContext);

const SolutionsContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [list, setList, { get, add, remove, edit }] = useList<Solution>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(0);

  const solutionsList = React.useMemo<SolutionsContextType>(
    () => ({
      solutions: list,
      getSolution: get,
      addSolution: add,
      removeSolution: remove,
      editSolution: edit,
      setSolutions: setList,
      solutionsLoading: loading,
      setSolutionsLoading: setLoading,
      solutionsCount: count,
      setSolutionsCount: setCount
    }),
    [
      list,
      setList,
      get,
      add,
      remove,
      edit,
      loading,
      setLoading,
      count,
      setCount
    ]
  );

  return (
    <SolutionsContext.Provider value={solutionsList}>
      {children}
    </SolutionsContext.Provider>
  );
};

export { SolutionsContextProvider, useSolutionsContext };
