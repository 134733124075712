import { Spin } from 'antd';
import React from 'react';
import './spinner.css';

const LoadingSpinner = () => (
  <div className='spinner'>
    <Spin size={'large'} />
  </div>
);

export { LoadingSpinner };
