import './DesktopLayout.css';
import { Layout } from 'antd';
import { useState, useEffect } from 'react';
import { useAuthContext } from '../../../context/AuthContext';
import { SiderMenu } from '../../SiderMenu';
import { UserInfoButton } from '../components/UserInfoButton';
import { LogoutButton } from '../components';
import { useGroupsContext } from '../../../context/GroupsContext';
import { getGroups } from '../../../api/groups';
import { showError } from '../../../utils/showError';
import { getUser } from '../../../api/users';
import { useUsersContext } from '../../../context/UsersContext';
import { HamburgerIcon } from '../../HamburgerIcon';

type DesktopLayoutProps = {
  title: string;
  entryPathname: string;
  children: React.ReactNode;
};

const DesktopLayout: React.FC<DesktopLayoutProps> = ({ title, entryPathname, children }) => {
  const { user, clinicName } = useAuthContext();
  const { setUserStatuses } = useUsersContext();
  const { setGroups, setGroupsCount, setGroupsLoading } = useGroupsContext();

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const userFullName = `${user!.data.lastname} ${user!.data.firstname} ${
    user!.data.middlename
  }`.trim();

  /*
    Запросы, необходимые для нормального редактирования данных авторизованного пользователя
  */
  useEffect(() => {
    if (!user) {
      console.log('!user');
      return;
    }
    setGroupsLoading(true);
    getGroups(user.token, { limit: 999 })
      .then(({ payload }) => {
        setGroups(payload.groups);
        setGroupsCount(payload.count);
      })
      .catch((e) => {
        showError(e);
      })
      .finally(() => {
        setGroupsLoading(false);
      });
    getUser(user.data.id, user.token)
      .then(({ payload }) => {
        setUserStatuses(payload.statuses);
      })
      .catch((e) => {
        showError(e);
      });
  }, []);

  return (
    <Layout
      className='desktop-main-layout'
      style={{ minHeight: '100vh' }}>
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={isCollapsed}
        onCollapse={setIsCollapsed}>
        <button
          id='desktop-sider-collapser'
          onClick={() => {
            setIsCollapsed((prev) => !prev);
          }}
        />
        <div className='logo'>
          <label
            className={`hamburger-button${!isCollapsed ? ' hamburger-button--is-opened' : ''}`}
            htmlFor='desktop-sider-collapser'
            children={HamburgerIcon}
          />
          {!isCollapsed && <span className='unselectable-text'>{clinicName}</span>}
        </div>
        <SiderMenu
          type='desktop'
          pathname={entryPathname}
        />
      </Layout.Sider>
      <Layout>
        <Layout.Header
          style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
          <div className='location unselectable-text'>{title && title}</div>
          <div className='user-section'>
            <div className='user-section__fullname'>{userFullName}</div>
            <UserInfoButton />
            <LogoutButton />
          </div>
        </Layout.Header>
        <Layout.Content style={{ margin: 16, overflow: 'initial' }}>
          <div
            className='content__container'
            style={{ padding: 16, maxHeight: 'calc(100vh - 64px - 32px)' }}>
            {children}
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export { DesktopLayout };
