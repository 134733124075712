import { Menu } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import { privateRoutes } from '../../router/routes';
import { convertExtendedRoutesToMenuItems, filterRoutesByAccessible } from '../../utils/routes';

type SiderMenuProps = {
  onChange?: () => void;
  type: 'mobile' | 'desktop';
  pathname: string;
};

const SiderMenu: React.FC<SiderMenuProps> = ({ onChange, type, pathname }) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const accessibleItems = React.useMemo(
    () => filterRoutesByAccessible(privateRoutes, user!.permissions, user!.properties),
    [user]
  );

  const onMenuItemSelect: React.ComponentProps<typeof Menu>['onSelect'] = (item) => {
    navigate(item.key);
  };

  return (
    <Menu
      theme='light'
      className={type === 'mobile' ? 'mobile-menu' : 'desktop-menu'}
      mode='inline'
      selectedKeys={[pathname]}
      items={convertExtendedRoutesToMenuItems(accessibleItems)}
      onSelect={onMenuItemSelect}
      onClick={() => {
        onChange?.();
      }}
    />
  );
};

export { SiderMenu };
